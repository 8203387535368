import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails,Card,Grid,CardContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useState } from 'react';
import './FAQ.css'
import { useNavigate } from 'react-router-dom';


export default function FAQ() {
    const [expandedAccordion, setExpandedAccordion] = useState('accordion1');
const navigate = useNavigate('');
const back=()=>{
navigate(-1)
}
    const handleChange = (accordion) => (event, isExpanded) => {
      setExpandedAccordion(isExpanded ? accordion : null);
    };
  return (
    <div className='background-faq p-1'>
    <div className='back-icon'><a  onClick={back}className='text-light fw-bold'><ArrowCircleLeftOutlinedIcon/></a> </div>
    <div className='faq-bg' >
              <Grid item >
        <Card elevation={3} style={{ padding: 20 }} id='inner-bg-faq'>
          <CardContent className='accordion-head' >
            <Typography variant="h4" gutterBottom className='text-center'>
             <b>Frequently Ask <span className='asked-que text-danger'>Questions</span></b>
            </Typography>
            <div className='mt-3 '>
      <Accordion
        expanded={expandedAccordion === 'accordion1'}
        onChange={handleChange('accordion1')}
         
         
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon  />}  className='accordion-body'>
          <Typography><b>01. How to I request a booking ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='text-dark'>
            Details of Accordion Item 1.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion2'}
        onChange={handleChange('accordion2')}
         
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon   />}>
          <Typography><b>02. How would I know my fare charges and bill amount ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='text-dark'>
            Details of Accordion Item 2.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion3'}
        onChange={handleChange('accordion3')}
         
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon    />}>
          <Typography><b>03. How will I receive my invoice ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='text-dark'>
            Details of Accordion Item 3.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion4'}
        onChange={handleChange('accordion4')}
         
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon    />}>
          <Typography><b>04. Can I place a booking with multi-point pick-up and drop ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='text-dark'>
            Details of Accordion Item 4.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion5'}
  onChange={handleChange('accordion5')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>05. How can I cancel my booking? Will I be charged a cancellation fee ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 5.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion6'}
  onChange={handleChange('accordion6')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>06. Is there a possibility of the truck being a no-show once the booking has been confirmed ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 6.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />
<Accordion
        expanded={expandedAccordion === 'accordion7'}
        onChange={handleChange('accordion7')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>07. Can I change the vehicle type after it has reached the pick-up point ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 1.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion8'}
        onChange={handleChange('accordion8')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>08. Do you also provide loading/unloading and packing services ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 2.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion9'}
        onChange={handleChange('accordion9')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>09. How many days or hours in advance do I need to make my booking ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 3.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion10'}
        onChange={handleChange('accordion10')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>10. Who will generate an e-Way bill ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 4.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion11'}
  onChange={handleChange('accordion11')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>11. Estimate fare shown in the website is it the Final Fare ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 5.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion12'}
  onChange={handleChange('accordion12')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>12. What are the additional charges or waiting charges ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 6.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />
<Accordion
        expanded={expandedAccordion === 'accordion13'}
        onChange={handleChange('accordion13')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>13. What are the GST charges on fare amount ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 1.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion14'}
        onChange={handleChange('accordion14')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>14. How to add a trip in the TransportBook App ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 2.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion15'}
        onChange={handleChange('accordion15')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>15. How to add trip charges in transport app ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 3.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion16'}
        onChange={handleChange('accordion16')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>16. How to check trip details ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 4.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion17'}
  onChange={handleChange('accordion17')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>17. How to check trip expense ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 5.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion18'}
  onChange={handleChange('accordion18')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>18. How to check truck trip book in the TransportBook App ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 6.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />
<Accordion
        expanded={expandedAccordion === 'accordion19'}
        onChange={handleChange('accordion19')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>19. How to add a truck in TransportBook App ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 1.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion20'}
        onChange={handleChange('accordion20')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>20. How can I improve my truck transportation business ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 2.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion21'}
        onChange={handleChange('accordion21')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>21. How can we improve my truck transportation efficiency ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 3.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion22'}
        onChange={handleChange('accordion22')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>22. What is the maximum loading capacity of a mini truck ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 4.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion23'}
  onChange={handleChange('accordion23')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>23. What is GRCTOB logistic ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 5.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion24'}
  onChange={handleChange('accordion24')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>24. How can I contact with GRCTOB ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 6.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />
<Accordion
        expanded={expandedAccordion === 'accordion25'}
        onChange={handleChange('accordion25')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>25.  What is [Your Logistic Web Application] ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 1.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion26'}
        onChange={handleChange('accordion26')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>26. How can I sign up for an account ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 2.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion27'}
        onChange={handleChange('accordion27')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>27. What services does [Your Logistic Web Application] offer ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 3.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion28'}
        onChange={handleChange('accordion28')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>28. How do I reset my password ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 4.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion29'}
  onChange={handleChange('accordion29')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>29. Can I update my profile information ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 5.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion30'}
  onChange={handleChange('accordion30')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>30. Q6: How can I track my shipments ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 6.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion31'}
        onChange={handleChange('accordion31')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>31. What information is available in the shipment tracking details ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 3.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion32'}
        onChange={handleChange('accordion32')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>32. How can I view and manage my invoices ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 4.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion33'}
  onChange={handleChange('accordion33')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>33. What payment methods are accepted ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 5.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion34'}
  onChange={handleChange('accordion34')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>34. How do I contact technical support ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 6.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />
<Accordion
        expanded={expandedAccordion === 'accordion35'}
        onChange={handleChange('accordion35')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>35.  Are there tutorials or user guides available ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 1.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion36'}
        onChange={handleChange('accordion36')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>36. How is my data secured ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 2.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion37'}
        onChange={handleChange('accordion37')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>37. What is your privacy policy ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 3.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion38'}
        onChange={handleChange('accordion38')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>38. Is there a mobile app available for [Your Logistic Web Application] ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 4.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion39'}
  onChange={handleChange('accordion39')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography><b>39.  What should I do if my shipment is delayed ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 5.
    </Typography>
  </AccordionDetails>
</Accordion>
<div style={{ margin: '16px' }} />

<Accordion
  expanded={expandedAccordion === 'accordion40'}
  onChange={handleChange('accordion40')}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />} >
    <Typography><b>40. How do I report a technical issue or bug ?</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      Details of Accordion Item 6.
    </Typography>
  </AccordionDetails>
</Accordion>
    </div>
    
          </CardContent>
        </Card>
      </Grid>
    </div>
    </div>
  )
}
