import React, { useState, useEffect } from 'react';


import './OwnerInterface.css'; // Import your CSS file
import { useNavigate } from 'react-router-dom';
import OwnerNavbar from './OwnerNavbar';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import Footer from "./Footer";

import CryptoJS from 'crypto-js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const style= {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
 
function OwnerInterface() { 
    
  
 const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [fromPincode, setFromPincode] = useState('');
    const [toPincode, setToPincode] = useState('');
    const [results, setResults] = useState([]);
    const [Error,setError]= useState()
    const [decryptedData, setDecryptedData] = useState(null);
  
    useEffect(() => {
      // Retrieve encoded data from URL parameters
      const params = new URLSearchParams(window.location.search);
      const encodedData = params.get('data');
      console.log('Encoded Data:', encodedData);
  
      if (encodedData) {
        try {
          // Decode and convert byte code back to original data
          const decodedBytes = CryptoJS.enc.Base64.parse(decodeURIComponent(encodedData));
          console.log('Decoded Bytes:', decodedBytes);
  
          const decryptedString = decodedBytes.toString(CryptoJS.enc.Utf8);
          console.log('Decrypted String:', decryptedString);
  
          const decryptedData = JSON.parse(decryptedString);
          console.log('Decrypted Data:', decryptedData);
  
          setDecryptedData(decryptedData);
        } catch (error) {
          console.error('Error decoding data:', error);
        }
      }
    }, []);
  
    useEffect(() => {
      if (decryptedData) {
        console.log('Decrypted Data inside useEffect:', decryptedData);
  console.log(decryptedData.userCRN)
        // Store each field in local storage
        try {
          if (decryptedData) {
            localStorage.setItem('userCRN', decryptedData.userCRN); // Corrected field name
            localStorage.setItem('name', decryptedData.name);
            localStorage.setItem('owneremail', decryptedData.owneremail);
            localStorage.setItem('phonenumber', decryptedData.phonenumber);
            localStorage.setItem('userToken', decryptedData.token);
            localStorage.setItem('feildCRN', decryptedData.feildCRN);
            localStorage.setItem('userName', decryptedData.name);
            // Check if values are stored correctly
          
  
            // Fetch data from the backend API based on CRN
            const fetchData = async (crn, token) => {
              try {
                console.log('crnss', crn);
                const headers = {
                  Authorization: `Bearer ${token}`,
                };
                const response = await fetch(`https://mynode.trucksbooking.in/truck/${crn}`, {
                  headers: headers,
                });
                const result = await response.json();
                setData(result);
              } catch (error) {
                console.error('Error fetching data:', error);
                if(!token){
                  navigate('/Login')
                }
              }
            };
  
            fetchData(crn, token);
          }
        } catch (error) {
          console.error('Error storing data in localStorage:', error);
        }
      }
    }, [decryptedData]); // Dependency on decryptedData ensures this runs after it's set
  
    // Retrieve values from localStorage
    const crn = localStorage.getItem('userCRN');
    const name = localStorage.getItem('name');
    const owneremail = localStorage.getItem('owneremail');
    const phonenumber = localStorage.getItem('phonenumber');
    const token = localStorage.getItem('userToken');
    const feildcrn = localStorage.getItem('feildCRN');
    const userName = localStorage.getItem('userName');
  
    console.log(crn, token);

const headers = {
    Authorization: `Bearer ${token}`,
  };
useEffect(() => {
  // Fetch data from the backend API based on CRN
  const fetchData = async (crn) => {
    try {
      console.log('crnss', crn);
      // Replace with the actual CRN number or get it dynamically
      const response = await fetch(`https://mynode.trucksbooking.in/truck/${crn}`,{headers:headers
    });
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
      if(!token){
        navigate('/Login')
      }
    }
  };

  fetchData(crn); 
}, [crn]);



  
   
const modes = ['driving', 'trucking', 'walking', 'biking'];

  const calculateDistance = () => {
    const apiKey = '6eab0fa444c0b3ea0ddce568eceef557'; // Replace with your actual MapPLS API key

    // Create an array to hold promises for fetching data for each mode
    const promises = modes.map(mode => {
      const apiUrl = `https://apis.mappls.com/advancedmaps/v1/${apiKey}/distance_matrix/${mode}/${fromPincode}%3B${toPincode}?rtype=0&region=ind`;
      return fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data.results && data.results.distances && data.results.durations) {
            const distance = data.results.distances[0][1] / 1000;
            const duration = data.results.durations[0][1] / 60;
            return { mode, distance, duration };
          } else {
            throw new Error('Distance or duration not found in the response.');
          }
        })
        .catch(error => {
          console.error(`Error fetching data for ${mode}:`, error);
          return { mode, distance: null, duration: null, error: `Error fetching distance for ${mode}. ${error.message}` };
        });
    });

    // Execute all promises simultaneously using Promise.all
    Promise.all(promises)
      .then(results => {
        setResults(results);
        setError(null);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(`Error fetching distance. ${error.message}`);
      });
  };
 
  
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);




const [showSuccessMessage, setShowSuccessMessage] = useState(false);

useEffect(() => {
  // Check if the success message should be displayed
  const shouldDisplayMessage = sessionStorage.getItem('displaySuccessMessage');

  if (shouldDisplayMessage) {
    // Display the success message
    setShowSuccessMessage(true);
    // Remove the flag from sessionStorage
    sessionStorage.removeItem('displaySuccessMessage');
    // Automatically hide the message after 5 seconds
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 5000);
  }
}, []);

  return (
    <div className='ownerbg'  >
      <OwnerNavbar/>

      {showSuccessMessage && (
        <div className="success-message mt-4 p-3" >
              <Alert severity="success" variant='filled'>
              <strong> Login Successful !</strong>

             </Alert>
        </div>
      )}
<center>{name} Transport PVT LTD</center>
      <div className='zindex'  style={{overflow:"none"}}> 
      <Box  className="container-fulid" style={{overflow:"none"}} >
      <Grid container  spacing={6} >
        <Grid xs={10} md={5}  >
        <Typography variant='h4' bgcolor={'orange'} className='mb-2 mt-5 ' sx={{textAlign:'center',borderRadius:'5px' }}>
            Distance Calculator
          </Typography>
          <form className='container' style={{height:'fit-content'}} >
 
      <Grid container spacing={2} justify="center" style={{border:'1px solid grey',padding:'10px'}} className='mt-3'>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
           label="From Pincode"
           variant="outlined"
           name="toPincode"
           id="fromPincode" 
           value={fromPincode} 
           onInput={(e) => {
            // Allow only digits
            e.target.value = e.target.value.replace(/\D/g, ''); 
            
            // Ensure maximum length of 6 digits
            if (e.target.value.length > 6) {
              e.target.value = e.target.value.slice(0, 6);
            }
    
            setFromPincode(e.target.value);
          }}
           placeholder="Enter From Pincode"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
             label="To Pincode"
             variant="outlined"
             name="toLocation"
             id="toPincode"
              value={toPincode}
              onInput={(e) => {
                // Allow only digits
                e.target.value = e.target.value.replace(/\D/g, ''); 
                
                // Ensure maximum length of 6 digits
                if (e.target.value.length > 6) {
                  e.target.value = e.target.value.slice(0, 6);
                }
        
                setToPincode(e.target.value);
              }}
             placeholder="Enter To Pincode"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <div className=' d-flex justify-content-center flex-row' > 
      <Button onClick={calculateDistance} variant='contained' className='w-50 ' >Submit  </Button>
      </div>
      </Grid>
    </Grid>
    {/* {results.error && <div className="alert alert-danger mt-3" role="alert">{results.error}</div>} */}
    {results.length > 0 && (
        <div className="table-responsive mt-3">
          <table className="table table-bordered table-primary table-hover">
            <thead>
              <tr>
                <th>Mode</th>
                <th>Distance (km)</th>
                <th>Duration (minutes)</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={result.mode}>
                  <td style={{ fontWeight: 'bold', backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>{result.mode}</td>
                  <td style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>{result.distance !== null ? result.distance.toFixed(2) : 'N/A'}</td>
                  <td style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>{result.duration !== null ? result.duration.toFixed(2) : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
</form>

          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > 
        <div style={{overflowX:'scroll'}}> 
        <Box sx={style} >
        
        <Typography  variant="p" component="p"  className='text-center'>
           <table className='table border table-primary'>
            <tr>
              <th>From location - Pincode</th>
              <th>To Location - Pincode</th>
              <th> Distance KMs</th>
            </tr>
           </table>


           </Typography>
           <Typography className='mt-4 text-center text-primary' variant='h6'>
             Country-State-District-mandel
           </Typography>
        
        
         
        </Box>
        </div>
      </Modal>

        </Grid>
        <Grid xs={12} md={7} className='my-trucks-form' style={{height:'fit-content'}}>
  <Typography variant='h4' bgcolor={'orange'} className='mb-1 p-0 ' sx={{ textAlign: 'center', borderRadius: '5px' }}>
    My Trucks
  </Typography>

  {data.length === 0 ? (
   <div> 
   <Typography variant='body1' sx={{ textAlign: 'center', mt: 2 }}>
     No truck details available.
   </Typography>
   <div className="spinner-border mt-4" id='loading-ani-in-avilable-trucks' role="status">
 <span className="visually-hidden ">Loading...</span>
</div>
   </div>
  ) : (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" className='table border table-striped table-hover' component={Paper}>
        <TableHead>
          <TableRow>
            <StyledTableCell>S.No</StyledTableCell>
            <StyledTableCell align="center">Truck Number</StyledTableCell>
            <StyledTableCell align="center">Approved Status</StyledTableCell>
            <StyledTableCell align="center">Posting Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {data.map((truck, index) => (
            <StyledTableRow key={truck.truckNumber} >
              <StyledTableCell component="th" scope="row">
                {index + 1}
              </StyledTableCell>
              <StyledTableCell style={{textAlign:'center',fontWeight:'bold'}}>{truck.truckNumber}</StyledTableCell>
              <StyledTableCell style={{textAlign:'center',fontWeight:'bold',textTransform:'capitalize'}}>{truck.status}</StyledTableCell>
              <TableCell style={{textAlign:'center',fontWeight:'bold',textTransform:'capitalize'}}>
                {truck.status === 'pending' ? 'pending' : truck.truckstatus}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )}
</Grid>

      </Grid>
    </Box>
</div>    <Footer/>

      </div>
    
  );
}

export default OwnerInterface;