import React, { useState } from 'react';
import axios from 'axios';

function LocationPage() {
  const [selectedLocation, setSelectedLocation] = useState('');
  const [relatedInfo, setRelatedInfo] = useState('');
  const proxyUrl = 'https://atlas.mappls.com/api/places/textsearch/json'; // Replace with your proxy URL

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
    if (e.target.value.trim() !== '') {
      // Fetch related information based on the entered location
      getRelatedInfo(e.target.value);
    }
  };

  const getRelatedInfo = async (query) => {
    const authToken = '1f2edf49-6e69-4898-b1b0-633303d87eb9';

    try {
      const response = await axios.get(`${proxyUrl}?query=${query}&region=IND`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setRelatedInfo(response.data);
    } catch (error) {
      console.error('Error fetching related information:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Enter location"
              value={selectedLocation}
              onChange={handleLocationChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div>
            {/* Display related information */}
            <pre>{JSON.stringify(relatedInfo, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationPage;