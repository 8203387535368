import React, { useState, useEffect } from 'react';

const Location = () => {
  const [fromPincode, setFromPincode] = useState('');
  const [toPincode, setToPincode] = useState('');
  const [result, setResult] = useState({ distance: null, duration: null, error: null });
  const [fromLocationSuggestions, setFromLocationSuggestions] = useState([]);
  const [toLocationSuggestions, setToLocationSuggestions] = useState([]);

  const calculateDistance = () => {
    // Your existing code for distance calculation
  };

  const handleLocationSearch = async (input, setLocationSuggestions) => {
    try {
      const client_id = '96dHZVzsAuvCPu7_NKpApI2dRRzLRH4y91FEYeRwNs4reVBjIlwhaKwmwRXeKbvnto8LgzHzXMiD1WG8EobpVQ=='; // Replace with your actual client ID
      const client_secret = 'lrFxI-iSEg97wVOUMPzANWL7Lm8NV0GgY5C2Esw-XvHp8bfEk4XbPg_3gioy7hg-9JdsxKAIaLdPI1j-d2Kp7HmEiCXNcLsa'; // Replace with your actual client secret
      const tokenApiUrl = 'https://outpost.mappls.com/api/security/oauth/token';
      
      // Fetch the access token
      const tokenResponse = await fetch(tokenApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `grant_type=client_credentials&client_id=${client_id}&client_secret=${client_secret}`,
       
      });
  
      if (!tokenResponse.ok) {
        throw new Error(`Error fetching access token! Status: ${tokenResponse.status}`);
      }
  
      const tokenData = await tokenResponse.json();
      const accessToken = tokenData.access_token;
  console.log(accessToken)
      // Use the access token to fetch location suggestions
      const apiUrl = `https://atlas.mapmyindia.com/api/places/geocode?address=${input}&itemCount=5`;
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error fetching location data! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data.results && data.results.length > 0) {
        setLocationSuggestions(data.results);
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
    }
  };
  

  useEffect(() => {
    if (fromPincode) {
      handleLocationSearch(fromPincode, setFromLocationSuggestions);
    }
  }, [fromPincode]);

  useEffect(() => {
    if (toPincode) {
      handleLocationSearch(toPincode, setToLocationSuggestions);
    }
  }, [toPincode]);

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Pincode-to-Pincode Distance Calculator</h2>
      <div className="mb-3">
        <label htmlFor="fromPincode" className="form-label">
          From Pincode:
        </label>
        <input
          type="text"
          style={{ maxWidth: '20%' }}
          className="form-control"
          id="fromPincode"
          value={fromPincode}
          onChange={(e) => setFromPincode(e.target.value)}
        />
        {fromLocationSuggestions.length > 0 && (
          <select
            className="form-select mt-2"
            onChange={(e) => setFromPincode(e.target.value)}
          >
            {fromLocationSuggestions.map((location) => (
              <option key={location.id} value={location.placeName}>
                {location.placeName}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="toPincode" className="form-label">
          To Pincode:
        </label>
        <input
          type="text"
          style={{ maxWidth: '20%' }}
          className="form-control"
          id="toPincode"
          value={toPincode}
          onChange={(e) => setToPincode(e.target.value)}
        />
        {toLocationSuggestions.length > 0 && (
          <select
            className="form-select mt-2"
            onChange={(e) => setToPincode(e.target.value)}
          >
            {toLocationSuggestions.map((location) => (
              <option key={location.id} value={location.placeName}>
                {location.placeName}
              </option>
            ))}
          </select>
        )}
      </div>
      <button type="button" className="btn btn-primary" onClick={calculateDistance}>
        Calculate Distance
      </button>
      <div className="mt-3">
        {/* Your existing code for displaying results */}
      </div>
    </div>
  );
};

export default Location;
