import React, { useState, useEffect } from 'react';
import './TruckPostiing.css'
// eslint-disable-next-line
import 'react-datepicker/dist/react-datepicker.css';
import Footer from "./Footer";

import Select from 'react-select';
import axios from 'axios';
import OwnerNavbar from './OwnerNavbar';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Button } from '@mui/material';
import { Alert }from '@mui/material';
function TruckPosting() {
  const navigate = useNavigate('')
  const [showOtpField, setShowOtpField] = useState(false);
  const[dis, setDis] = useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [otpverification,setOtpverification]= useState(false)
  const [otpinvalid,setOtpinvalid]= useState(false)
  const [pricePerTonKm, setPricePerTonKm] = useState('');
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [registrationNumbers, setRegistrationNumbers] = useState('');
  const [selectedRegistrationNumber, setSelectedRegistrationNumber] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [otp, setOtp] = useState('');

  const [showAll, setShowAll] = useState(false);
  const [pincode, setPincode] = useState('');
  const [pincodeData, setPincodeData] = useState([]);
  const [selectedPincode, setSelectedPincode] = useState('');
  const [selectedPincode1, setSelectedPincode1] = useState('');
  const [selectedSublocation1, setSelectedSublocation1] = useState('');

  const [selectedSublocation, setSelectedSublocation] = useState('');
  const [locationDetails, setLocationDetails] = useState({ });
  const [locationDetails1, setLocationDetails1] = useState({
    location: '',
    mainLocation: '',
    sublocation: '',
  });
  const crn = localStorage.getItem('userCRN');
  console.log(crn)
  
  const token = localStorage.getItem('userToken')

const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isTruckAlreadyPosted, setIsTruckAlreadyPosted] = useState(false);
  const getToday = () => {
    const now = new Date();
    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are 0-indexed
    const year = now.getFullYear().toString();
    return `${year}-${month}-${day}`;
  };
  
  const [kilometers, setKilometers] = useState('');
  const [selectedSubLocations, setSelectedSubLocations] = useState([]);
  const [selectedSubLocations1, setSelectedSubLocations1] = useState([]);
  useEffect(() => {
    // Fetch pincode data when pincode changes
    if (pincode.length >= 3) {
     
      axios.get(`https://mynode.trucksbooking.in/fetchpincode/${pincode}`,{headers:headers})
        .then((response) => {
          setPincodeData(response.data);
         
        })
        .catch((error) => {
        
          console.error('Error fetching pincode data:', error);
        });
    }
  }, [pincode]);
  console.log('set',selectedPincode)
  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setDate(today.getDate() + 7); // Disable dates within the next 7 days
    const day = maxDate.getDate().toString().padStart(2, '0');
    const month = (maxDate.getMonth() + 1).toString().padStart(2, '0');
    const year = maxDate.getFullYear().toString();
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    // Fetch location details when selectedPincode or selectedSublocation changes
    console.log('Selected Pincode:', selectedPincode);
    console.log('Selected Sublocation:', selectedSublocation);
 
    if (selectedPincode && selectedSublocation) {
      axios
        .get(`https://mynode.trucksbooking.in/details/${selectedPincode}/${selectedSublocation}`,{headers:headers})
        .then((response) => {
          setLocationDetails(response.data);
          console.log(response.data);
          
        })  
        .catch((error) => {
       
          console.error('Error fetching location details:', error);
        });
    }
  }, [selectedPincode, selectedSublocation]);
  useEffect(() => {
    // Fetch location details when selectedPincode or selectedSublocation changes
    console.log('Selected Pincode1:', selectedPincode1);
    console.log('Selected Sublocation1:', selectedSublocation1);
   
    if (selectedPincode1 && selectedSublocation1) {
      axios
        .get(`https://mynode.trucksbooking.in/details/${selectedPincode1}/${selectedSublocation1}`,{headers:headers})
        .then((response) => {
          setLocationDetails1(response.data);
          console.log(response.data);
        
        })
        .catch((error) => {
         
          console.error('Error fetching location details:', error);
        });
    }
  }, [selectedPincode1, selectedSublocation1]);
  const handleInputChange = (inputValue) => {
    setPincode(inputValue);
    setSelectedPincode(inputValue)
    // Add additional logic if needed for the second state variable
  };
  const handleInputChange1 = (inputValue) => {
    setPincode(inputValue);
    setSelectedPincode(inputValue)
    // Add additional logic if needed for the second state variable
  };

  const handlePincodeChange = (option) => {
    const [selectedPincode, selectedSublocation] = option.value.split(' - ');
    setSelectedPincode(selectedPincode);
    setSelectedSublocation(selectedSublocation);
    console.log('Selected Pincode:', selectedPincode);
    console.log('Selected Sublocation:', selectedSublocation);
  };
  const handlePincodeChange1 = (option) => {
    const [selectedPincode1, selectedSublocation1] = option.value.split(' - ');
    setSelectedPincode1(selectedPincode1);
    setSelectedSublocation1(selectedSublocation1);
    console.log('Selected Pincode1:', selectedPincode1);
    console.log('Selected Sublocation1:', selectedSublocation1);
  };
  useEffect(()=>{
    if(!token){
  navigate('/Login')
    }
  })
  const fetchRegistrationNumbersByCRN = async () => {
  
    try {
      setOpenBackdrop(true);
      const response = await axios.get(`https://mynode.trucksbooking.in/truckNumber?crn=${crn}`,{headers:headers});
      if (Array.isArray(response.data)) {
        const completedTrucks = response.data.filter((truck) => truck.status === 'Completed');
        console.log(completedTrucks)
      setRegistrationNumbers(completedTrucks.map((truck) => truck.truckNumber));
      setOpenBackdrop(false);
      } else {
        setRegistrationNumbers([]); // If the response is not an array, set an empty array
      }
    } catch (error) {
      setOpenBackdrop(false);
      console.error('Error fetching registration numbers:', error);
      setRegistrationNumbers([]);
    }
  };
  useEffect(() => {
    fetchRegistrationNumbersByCRN();
  }, []); 
 
  const mainLocations = [
    { label: 'Hyderabad', value: 'Hyderabad' },
    { label: 'Bangalore', value: 'Bangalore' },
    { label: 'Krishna', value: 'Krishna' },
  ];
  
  const subLocations = {
    Hyderabad: [
      { label: 'Madhapur', value: 'Madhapur' },
      { label: 'Kondapur', value: 'Kondapur' },
    ],
    Bangalore: [
      { label: 'Koramangala', value: 'Koramangala' },
      { label: 'Indiranagar', value: 'Indiranagar' },
    ],
    Krishna: [
      { label: 'Machilipatnam', value: 'Machilipatnam' },
      { label: 'Vijayawada', value: 'Vijayawada' },
    ],
  };
  const [fromMainLocation, setFromMainLocation] = useState(null);
  const [fromSubLocations, setFromSubLocations] = useState([]);

  const [toMainLocation, setToMainLocation] = useState(null);
  const [toSubLocations, setToSubLocations] = useState([]);

  const handleFromMainLocationChange = (selectedOption) => {
    setFromMainLocation(selectedOption);
    setFromSubLocations([]);
  };

  const handleFromSubLocationChange = (selectedOptions) => {
    setFromSubLocations(selectedOptions || []); // Ensure to set an empty array if selectedOptions is null
  };
  
  const handleToSubLocationChange = (selectedOptions) => {
    setToSubLocations(selectedOptions || []); // Ensure to set an empty array if selectedOptions is null
  };

  const handleToMainLocationChange = (selectedOption) => {
    setToMainLocation(selectedOption);
    setToSubLocations([]);
  };

 

  useEffect(() => {
    // Call the function to check truck post status when the selected truck number changes
    checkTruckAvailability();
  }, [selectedRegistrationNumber]);
  const checkTruckAvailability = async () => {
    try {
      setOpenBackdrop(true);
      const response = await axios.get(`https://mynode.trucksbooking.in/checkTruckAvailability/${selectedRegistrationNumber}`, { headers });
      const truckDetails = response.data;
      console.log('truc',truckDetails)

      if (truckDetails.length > 0) {
        // Truck is found in the database
        const latestTruckDetail = truckDetails[0];
        const availableDate = new Date(latestTruckDetail.date);
        const currentDate = new Date();
  
  
        if (availableDate > currentDate) {
          // Truck has already been posted for a future date
          alert(`Truck with this number has already been posted for the specified date.${availableDate}`);
          setOpenBackdrop(false);
          window.location.reload()
        } else {
          // Available date has expired, allow the user to proceed
          // alert('Available date has expired. You can proceed to post the truck.');
          alert('Available date has expired (or) booked. You can proceed to post the truck.');
          setOpenBackdrop(false);
        }
      } else {
        // Truck with this number is not found, allow the user to proceed
        alert('Truck with this number has not been posted. You can proceed to post the truck.');
        setOpenBackdrop(false);
      }
    } catch (error) {
      console.error('Error checking truck availability:', error);
    }
  };

  const handleSendOtp = () => {
    // Check if all required fields have a value
    setOpenBackdrop(true);
    if (!selectedRegistrationNumber  || !date || !time || !pricePerTonKm) {
      alert('Please enter a value for all fields.');
      setOpenBackdrop(false);
    } else {
      const generatedOtp = '123456';
      setShowOtpField(true);
      setDis(true);
      console.log(`OTP Sent: ${generatedOtp}`);
      setOpenBackdrop(false);
    }
  };
  const handleVerifyOtp = () => {
    setOpenBackdrop(true);
    if (otp === '123456') {
      setIsOtpVerified(true);
      setIsFormDisabled(true); 
      alert('OTP Verified!');
      setOpenBackdrop(false);
    } else  {
    
      alert('Invalid OTP! Please try again.');
      setOpenBackdrop(false);
    } 
  };
 
  const handlePost = () => {
    if (isOtpVerified) {
      setOpenBackdrop(true);
     
      const generateTruckPostingID = () => {
        const now = new Date();
        const uniqueTime = now.getTime().toString();
        const random = Math.floor(100000 + Math.random() * 900000); // Generates a 6 digit random number
        return `TP${uniqueTime.slice(-5)}${random.toString().slice(-1)}`; // Ensures the ID is 6 characters long
      };

      const truckPostingID = generateTruckPostingID();
      const postData = {
        truckNumber: selectedRegistrationNumber,
        date,
        time,
        from: fromMainLocation?.label, // Use label property of fromMainLocation
        to: toMainLocation?.label, // Use label property of toMainLocation
        loadingSublocations: `{${fromSubLocations.map(subLocation => subLocation.label).join(',')}}`,
        unloadingSublocations: `{${toSubLocations.map(subLocation => subLocation.label).join(',')}}`,
        ton: '1',
        kilometer: '1',
        pricePerTonKm,
        crn,
        truckPostingID, 
      };
      console.log(postData);
      axios
        .post('https://mynode.trucksbooking.in/Post', postData,{
          headers:headers
        }) // Replace with your actual API endpoint
        .then((response) => {
          const responseData = response.data;
          console.log(responseData);
          console.log(postData)
          alert('Truck posted successfully.');
          setOpenBackdrop(false);
          navigate('/OwnerInterface')
        })
        .catch((error) => {
          console.error('Error posting data:', error);
          setOpenBackdrop(false);
          alert('Error posting data. Please try again.');
        });
        setOpenBackdrop(true);
        axios
        .post('https://mynode.trucksbooking.in/Post1', postData,{
          headers:headers
        }) // Replace with your actual API endpoint for the second table
        .then((response) => {
          const responseData = response.data;
          console.log(responseData);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          console.error('Error posting data to the second table:', error);
      
          alert('Error posting data to the second table. Please try again.');
          setOpenBackdrop(false);
        });
      }else {
        alert('OTP not verified. Cannot post.');
        setOpenBackdrop(false);
      }

    setDate('')
    setTime('')
    setOtp('')
    setRegistrationNumbers('')
    setSelectedSubLocations('')
    setSelectedSubLocations1('')
    setIsOtpVerified(false);
    setIsFormDisabled(true);
  }
  const tonValues = [5, 10, 15];
  const kilometerValues = [100, 200, 300];

  const calculateApproximatePrices = () => {
    if (!pricePerTonKm || isNaN(pricePerTonKm) || !kilometerValues || isNaN(kilometers)) {
      // If the entered price or kilometers are not valid numbers, you can handle it here
      return [];
    }
  
    const price = parseFloat(pricePerTonKm);
    const enteredKilometers = parseFloat(kilometerValues);
  
    return tonValues.map((ton) => {
      return {
        ton,
        enteredKilometers,
        calculatedPrice: price * ton * enteredKilometers,
      };
    });
  };

  const renderApproximations = () => {
    const approximations = calculateApproximatePrices();
    const displayCount = showAll ? approximations.length : 2;

    return (
      <div>
        {approximations.slice(0, displayCount).map((approximation, index) => (
          <div key={index}>
            <p>
              For <span style={{ color: 'red' }}>{approximation.ton}T</span>  and <span style={{ color: 'red' }}>{approximation.enteredKilometers}Km </span>, the price is approximately{' '}
              <span style={{ color: 'red' }}>{approximation.calculatedPrice} Rs.</span>
            </p>
          </div>
        ))}

        {approximations.length > 2 && (
          <button onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Show Less' : 'Show More'}
          </button>
        )}
      </div>
    );
  };
  return (
    <div className='truck-posting-bg'>
    <OwnerNavbar />
    <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
            onClick={null} // Set onClick to null to disable closing on click
          >
            <div>
            <CircularProgress color="inherit" />
            <br/>
            <Typography variant="h6" color="inherit" component="div" style={{marginTop: '10px'}}>
              Loading...
            </Typography>
            </div>
    
          </Backdrop>
    <div id='truck-posting-form'>
      {otpverification &&  <div className="text-success mt-4 p-3" >
          <Alert severity="success" variant='filled'>
          <strong> OTP Verified !</strong>
         </Alert>
    </div> }
    {otpinvalid &&  <div className="text-danger mt-4 p-3" >
          <Alert severity="error" variant='filled'>
          <strong> OTP Verified !</strong>
         </Alert>
    </div> }
    <div className="container  shadow hii-3">
      <h1 className="text-center txt">Truck Posting</h1>

      {/* Row for Registration Number */}
      <div className="row mt-4">
        <div className="col-md-4">
          <label>1. Registration Number<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
          <select
            value={selectedRegistrationNumber}
            className="form-control"
            onChange={(e) => setSelectedRegistrationNumber(e.target.value)}
            disabled={dis}
          >
            <option value="">- Select Registration Number -</option>
            {Array.isArray(registrationNumbers) ? (
              registrationNumbers.map((truckNumber) => (
                <option key={truckNumber} value={truckNumber}>
                  {truckNumber}
                </option>
              ))
            ) : (
              <option value="">No registration numbers available</option>
            )}
          </select>
        </div>
      

      {/* Row for From and To Pincode */}
    
      <div className="col-md-4">
          <label htmlFor="fromMainLocation">2. From Main Location</label>
          <Select
            value={fromMainLocation}
            onChange={handleFromMainLocationChange}
            options={mainLocations}
            placeholder="Select Main Location"
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="fromSubLocations">3. Sub Locations</label>
          <Select
            value={fromSubLocations}
            onChange={handleFromSubLocationChange}
            options={fromMainLocation ? subLocations[fromMainLocation.value] : []}
            isMulti
            placeholder="Select Sub Locations"
          />
        </div>
        </div>
        <div className="row mt-4">
        <div className="col-md-4">
          <label htmlFor="toMainLocation">4. To Main Location</label>
          <Select
            value={toMainLocation}
            onChange={handleToMainLocationChange}
            options={mainLocations.filter((location) => location.value !== (fromMainLocation && fromMainLocation.value))}
            placeholder="Select Main Location"
          />
        </div>
      
        <div className="col-md-4">
          <label htmlFor="toSubLocations">5. Sub Locations</label>
          <Select
            value={toSubLocations}
            onChange={handleToSubLocationChange}
            options={toMainLocation ? subLocations[toMainLocation.value] : []}
            isMulti
            placeholder="Select Sub Locations"
          />
        </div>
      {/* Row for Date, Ton, Kilometer, Price, and Time */}
      
        <div className="col-md-4">
          <label htmlFor="date">6. Date<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
          <input
type="date"
className="form-control"
id="date"
style={{ textTransform: 'uppercase' }}
value={date}
onChange={(e) => setDate(e.target.value)}
min={getToday()}
 // set the minimum date to today
disabled={dis}
dateFormat="dd-MM-yyyy"
placeholderText="DD-MM-YYYY"
/>
        </div>

        <div className="col-md-4 mt-4">
          <label htmlFor="time">7. Time</label>
          <input
            type="time"
            className="form-control"
            id="time"
            value={time}
            onChange={(e) => setTime(e.target.value)} disabled={dis}
            placeholder="Enter Number"
          />
        </div>
     
        <div className="col-md-1 mt-4">
          <label htmlFor="ton">Ton</label>
          <input
            type="text"
            className="form-control"
            id="ton"
            value='1 Ton'
            placeholder="Enter Ton"
            disabled
          />
        </div>

        <div className="col-md-1 mt-4">
          <label htmlFor="kilometer">Kilometer</label>
          <input
            type="text"
            className="form-control"
            id="kilometer"
            value='1 KM'
            disabled
          />
        </div>

        <div className="col-md-2 mt-4">
          <label htmlFor="totalKilometers">8. Price<sup style={{color:'red',fontSize:'15px'}}>*</sup></label>
          <input
  type="text"
  className="form-control"
  id="totalKilometers"
  value={pricePerTonKm}
  onChange={(e) => setPricePerTonKm(e.target.value)}
  placeholder="Price per KM"
  onInput={(e) => {
    // Allow only digits
    e.target.value = e.target.value.replace(/\D/g, '');

    // Ensure value does not start with 0
    if (e.target.value.startsWith('0')) {
      e.target.value = e.target.value.slice(1); // Remove leading 0
    }
  }}
  required
  maxLength={4}
/>
        </div>
       
     
        {renderApproximations()}
      </div>
     

      {showOtpField && (
        <div className="row mt-4">
          <div className="col-md-4">
            <label htmlFor="otp">6. OTP</label>
            <input
              type="text"
              id="otp"
              value={otp}
              className="form-control"
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              disabled={isFormDisabled}
            />
          </div>
        </div>
      )}


      <div className="row mt-4" >
        <div className="col-md-12">
          <div className="grid-container-3">
            {!isOtpVerified ? (
              <div className="flex-2">
                <Button
                  type="button"
                  variant='contained'
                  onClick={showOtpField ? handleVerifyOtp : handleSendOtp}
                  disabled={isFormDisabled}
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    padding: '5px 20px',
                    transition: 'background-color 0.3s, color 0.3s',
                  }}
                >
                  {showOtpField ? 'Verify OTP' : 'Send OTP'}
                </Button>
              </div>
            ) : (
              <div className="flex-2">
                {!isTruckAlreadyPosted ? (
                  <Button
                    type="button"
                    variant='contained'
                    onClick={handlePost}

                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      padding: '10px 20px',
                      transition: 'background-color 0.3s, color 0.3s',
                    }}
                  >
                    Post
                  </Button>
                ) : (
                  <p className="flex-2 btn btn-secondary">
                    Truck Already Posted
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </div>
    <Footer/>

  </div>
);
  ;
}

export default TruckPosting;
