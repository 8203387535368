import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css'
import './NewDriver.css'; // Import a custom CSS file for styling
import { useLocation } from 'react-router-dom';
import OwnerNavbar from './OwnerNavbar';
import Footer from "./Footer";
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { FamilyRestroomOutlined } from '@mui/icons-material';
export default function NewDriver() {
    const navigate = useNavigate();

    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const [driverName, setDriverName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [images, setImages] = useState({
        licenseFront: null,
        licenseBack: null,
        aadharFront: null,
        aadharBack: null,
        policeVerificationCertificate: null,
        healthCertificate: null,
        driverPhoto: null,
    });
    const [licenseIssuedDate, setLicenseIssuedDate] = useState('');
    const [licenseValidityDate, setLicenseValidityDate] = useState('');
    const [errors, setErrors] = useState('');
    const [dateOfJoining, setDateOfJoining] = useState('');
    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        setDateOfJoining(formattedDate);
    }, []);
    const crn = localStorage.getItem('userCRN')
    console.log(crn)
    const tenYearsAgo = new Date();
    tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 25);

    const tenYearsAhead = new Date();
    tenYearsAhead.setFullYear(tenYearsAhead.getFullYear() + 25);
    const handleImageChange = (e, imageKey) => {
        const selectedFile = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5MB
        const fieldName = e.target.name;

        if (selectedFile && selectedFile.size > maxSize) {
            setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: 'File size exceeds the maximum limit of 5MB.' }));
            console.log('error');
            e.target.value = ''; // Clear the file input field
        } else {
            setImages({ ...images, [imageKey]: e.target.files[0] });
            setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '' })); // Clear any previous errors
        }
    };
    const validateFileSize = (file, maxSizeInBytes) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
        if (!allowedTypes.includes(file.type)) {
            return 'File type should be JPG, JPEG, or PNG.';
        }
        if (file.size > maxSizeInBytes) {
            return `File size should not exceed ${maxSizeInBytes / (1024 * 1024)} MB.`;
        }
        return '';
    };
    const token = localStorage.getItem('userToken')
    useEffect(() => {
        if (!token) {
            navigate('/Login')
        }
    })
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const validateForm = () => {
        const newErrors = {};
        if (!driverName) {
            newErrors.driverName = 'Please enter the driver name.';
        }

        // Validate file sizes
        const maxSize = 5 * 1024 * 1024; // 15MB

        for (const key in images) {
            if (images[key]) {
                const errorMessage = validateFileSize(images[key], maxSize);
                if (errorMessage) {
                    newErrors[key] = errorMessage;
                }
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setOpenBackdrop(true);
        const formData = new FormData();

        formData.append('driverName', driverName);
        formData.append('phoneNumber', phoneNumber);


        formData.append('email', email);
        formData.append('licenseFront', images.licenseFront);
        formData.append('licenseBack', images.licenseBack);
        formData.append('aadharFront', images.aadharFront);
        formData.append('aadharBack', images.aadharBack);
        formData.append('policeVerificationCertificate', images.policeVerificationCertificate);
        formData.append('healthCertificate', images.healthCertificate);
        formData.append('driverPhoto', images.driverPhoto);
        formData.append('licenseIssuedDate', licenseIssuedDate);
        formData.append('licenseValidityDate', licenseValidityDate);
        formData.append('dateOfJoining', dateOfJoining);
        formData.append('crn', crn);

        // Create form data for file uploads
        // Send formData to the backend API using Axios
        console.log('formdata', [...formData.entries()]);
        axios.post('https://mynode.trucksbooking.in/driver', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Set the content type for file uploads
            }, headers: headers
        })
            .then((response) => {
                console.log(response.data);
                // Handle success, e.g., display a success message or navigate to another page
                setOpenBackdrop(FamilyRestroomOutlined);
                navigate('/OwnerInterface', { state: { crn } })
            })
            .catch((error) => {
                console.error(error);
                // Handle error, e.g., display an error message
                setOpenBackdrop(false);
            });
    };
   
    return (
        <div className=''>
            <OwnerNavbar />
            <div>

                <div className="custom-container">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" style={{ backgroundColor: '#e2eff1' }}>
                        <div className="shadow p-3 mb-3 rounded">
                            <h2 className='txt' style={{ fontFamily: 'Segoe UI', textShadow: '1px 2px 2px gray', marginBottom: '40px', textAlign: 'center' }}>Driver Registration</h2>
                            <div className="row ms-1 me-2">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>1. Driver Name<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="text"
                                            name="driverName"
                                            value={driverName}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^A-Za-z\s]+/g, '');
                                                setDriverName(e.target.value);
                                            }}
                                            className="form-control"
                                            placeholder='Enter Name' style={{ textTransform: 'capitalize' }} maxLength={40}
                                            required
                                        />                {errors.driverName && <span className="error">{errors.driverName}</span>}

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>2. Driver Mobile Number<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="text"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onInput={(e) => {
                                                let inputValue = e.target.value;

                                                // Remove non-digit characters
                                                inputValue = inputValue.replace(/\D/g, '');

                                                // Prevent entering '0' at the beginning
                                                if (inputValue.startsWith('0')) {
                                                    inputValue = inputValue.substring(1); // Remove the leading '0'
                                                }

                                                setPhoneNumber(inputValue);
                                            }} maxLength={10} minLength={10} className="form-control"
                                            placeholder='Enter Mobile Number'
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>3. Driver License No<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>

                                        <input
                                            type="text"
                                            id="email"
                                            value={email}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^A-Z0-9\s]+/g, '');


                                                setEmail(e.target.value);
                                            }}
                                            className="form-control "
                                            placeholder="Enter License No"
                                            style={{ textTransform: 'uppercase' }}
                                            maxLength={20}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="row ms-1 me-2">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>4. Driver License Front Side<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="file"
                                            name="licenseFront"
                                            accept=".pdf, .jpg, .jpeg, .png"

                                            onChange={(e) => handleImageChange(e, 'licenseFront')}
                                            className="form-control p-1"
                                            required
                                        />    {errors['licenseFront'] && (
                                            <span className="error">{errors['licenseFront']}</span>)}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>5. Driver License Back Side<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="file"
                                            name="licenseBack"
                                            accept=".pdf, .jpg, .jpeg, .png"

                                            onChange={(e) => handleImageChange(e, 'licenseBack')}
                                            className="form-control"
                                            required
                                        />    {errors['licenseBack'] && (
                                            <span className="error">{errors['licenseBack']}</span>)}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>6. License Issued Date<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="date"
                                            name="licenseIssuedDate"
                                            style={{ textTransform: 'uppercase' }}
                                            value={licenseIssuedDate}
                                            onChange={(e) => setLicenseIssuedDate(e.target.value)}
                                            className="form-control"
                                            min={tenYearsAgo.toISOString().split('T')[0]} // Ten years ago
                                            max={tenYearsAhead.toISOString().split('T')[0]} // Ten years ahead
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row ms-1 me-2">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>7. License Validity Date<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="date"
                                            name="licenseValidityDate"
                                            style={{ textTransform: 'uppercase' }}
                                            value={licenseValidityDate}

                                            onChange={(e) => setLicenseValidityDate(e.target.value)}
                                            className="form-control"
                                            min={licenseIssuedDate ? licenseIssuedDate : tenYearsAgo.toISOString().split('T')[0]}
                                            max={tenYearsAhead.toISOString().split('T')[0]}
                                            disabled={!licenseIssuedDate}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>8. Driver Aadhar Front Side<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="file"
                                            name="aadharFront"
                                            accept=".pdf, .jpg, .jpeg, .png"

                                            onChange={(e) => handleImageChange(e, 'aadharFront')}
                                            className="form-control"
                                            required
                                        />
                                        {errors['aadharFront'] && (
                                            <span className="error">{errors['aadharFront']}</span>)}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>9. Driver Aadhar Back Side<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="file"
                                            name="aadharBack"
                                            accept=".pdf, .jpg, .jpeg, .png"

                                            onChange={(e) => handleImageChange(e, 'aadharBack')}
                                            className="form-control"
                                            required
                                        />    {errors['aadharBack'] && (
                                            <span className="error">{errors['aadharBack']}</span>)}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row ms-1 me-2">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>10. Police Verification Certificate<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="file"
                                            name="policeVerificationCertificate"
                                            accept=".pdf, .jpg, .jpeg, .png"

                                            onChange={(e) => handleImageChange(e, 'policeVerificationCertificate')}
                                            className="form-control"
                                            required
                                        />    {errors['policeVerificationCertificate'] && (
                                            <span className="error">{errors['policeVerificationCertificate']}</span>)}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>11. Health Certificate<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="file"
                                            name="healthCertificate"
                                            accept=".pdf, .jpg, .jpeg, .png"

                                            onChange={(e) => handleImageChange(e, 'healthCertificate')}
                                            className="form-control"
                                            required
                                        />    {errors['healthCertificate'] && (
                                            <span className="error">{errors['healthCertificate']}</span>)}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label>12. Driver Photo<sup style={{ color: 'red', fontSize: '15px' }}>*</sup></label>
                                        <input
                                            type="file"
                                            name="driverPhoto"
                                            accept=".pdf, .jpg, .jpeg, .png"

                                            onChange={(e) => handleImageChange(e, 'driverPhoto')}
                                            className="form-control"
                                            required
                                        />    {errors['driverPhoto'] && (
                                            <span className="error">{errors['driverPhoto']}</span>)}<span style={{ opacity: 0.5 }}>JPG,JPEG,PNG,pdf(Max size: 5MB)</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button type="submit" className="btn btn-primary mt-3 mb-3 p-2">
                                    Create Account
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />

        </div>
    );
}
