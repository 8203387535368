// Navbar.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const storedUser = localStorage.getItem('name');
console.log(storedUser)
  useEffect(() => {
    const storedUser = localStorage.getItem('name');
    if (storedUser) {
     
      setUser(storedUser);
    }
  }, []);

  

  return (
    <nav className="navbar" style={{height:'60px'}}>
    <div className="navbar-logo">
     <h3 style={{marginLeft:'20px'}}>{user} Transport PVT LTD</h3>
    </div>
   
  </nav>
  );
};

export default Navbar;
