import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css';
import img from './images/grctob logo.png'
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';  
import Typography from '@mui/material/Typography';

import Navbar from './Navbar';
const Login = () => {
  const [phonenumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate input (you can add more validation)
    if (!phonenumber || !password) {
      setError('Please fill in all fields.');
      return;
    }
    

    try {
      setOpenBackdrop(true);

      // Send a request to your backend for authentication using axios
      const response = await axios.post('https://mynode.trucksbooking.in/auth', {
        phonenumber,
        password,
      });

      if (response.status === 200) {
        // Successful login
        const userData = response.data.user;
 const token = response.data.token;
 console.log('token',token)
        if (userData && userData.crn) {
          const userCRN = userData.crn;
          const feildCRN = userData.feildcrn;
          const owneremail= userData.owneremail;

          const name= userData.ownername;
          console.log(userCRN)
          console.log(feildCRN)
          const loggedStatus = userData.loggedstatus;
          localStorage.setItem('userToken', token);
          localStorage.setItem('onweremail',owneremail)
          // Store user CRN in localStorage
          localStorage.setItem('userCRN', userCRN);
          localStorage.setItem('userCRN', userCRN);
          localStorage.setItem('feildCRN', feildCRN);
          localStorage.setItem('phoneNumber', phonenumber);
          localStorage.setItem('password', password);
          localStorage.setItem('name', name);
          localStorage.setItem('userName', name);

          const loginTimestamp = new Date().getTime();
          localStorage.setItem('loginTimestamp', loginTimestamp);

        

          // Redirect to the dashboard
          console.log('Login successful');
          setOpenBackdrop(false);
          // Check the logged status
          if (loggedStatus === 'pending') {
            // Navigate to update password page
            navigate('/MyForm', {
              state: { crn: userCRN, name: name },
            });
          } else if (loggedStatus === 'completed') {
            // Navigate to Owner Interface
            
  navigate('/')
          } else {
            setError('Unknown logged status');
          }
        } else {
          setError('CRN not found in user data');
        }
      } else {
        // Failed login
        setOpenBackdrop(false);

        setError('Invalid phone number or password. Please try again.');
      }
    } catch (error) {
     
      
      setOpenBackdrop(false);

      setError('Invalid phone number or password. Please try again.');
      console.error('Login error:', error);
    }
};
  const token = localStorage.getItem('userToken')
  console.log(token)
useEffect(()=>{
    if(token){
      navigate('/')
    }
})
  return (
    <div><Navbar/>
       <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={null} // Set onClick to null to disable closing on click
      >
          <div>
        <CircularProgress color="inherit" />
        <br/>
        <Typography variant="h6" color="inherit" component="div" style={{marginTop: '10px'}}>
          Loading...
        </Typography>
        </div>

      </Backdrop> 
    <div className="login-container">
      <div className="login-box">
        <div className="login-image">
          <img src={img} alt="Login" />
        </div>
        <div className="vertical-line"></div>
        <div className="login-form">
          <h2>Truck Owner Login</h2>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="form-group">
            <label>Phone number</label>
            <input 
              type="email" 
              value={phonenumber} 
              onChange={(e) => setPhoneNumber(e.target.value)} 
              placeholder="phonenumber" 
              className={errorMessage && !phonenumber ? 'input-error' : ''}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Password" 
              className={errorMessage && !password ? 'input-error' : ''}
            />
          </div>
          <center><a href='/ForgotPasswordOwner'>forget password?</a></center>
          <br></br>
          <button className="login-button" onClick={handleLogin}>Login</button>
        </div>
      </div>
    </div></div>
  );
};

export default Login;
