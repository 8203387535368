import React, { useState } from 'react';
import Footer from "./Footer";

const DistanceCalculator = () => {
  const [fromPincode, setFromPincode] = useState('');
  const [toPincode, setToPincode] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);

  const modes = ['driving', 'trucking', 'walking', 'biking'];

  const calculateDistance = () => {
    const pincodeRegex = /^\d{6}$/;
    if (!pincodeRegex.test(fromPincode) || !pincodeRegex.test(toPincode)) {
      setError("Please enter valid 6-digit pin codes.");
      return;
    }
    const apiKey = '6eab0fa444c0b3ea0ddce568eceef557'; // Replace with your actual MapPLS API key

    // Create an array to hold promises for fetching data for each mode
    const promises = modes.map(mode => {
      const apiUrl = `https://apis.mappls.com/advancedmaps/v1/${apiKey}/distance_matrix/${mode}/${fromPincode}%3B${toPincode}?rtype=0&region=ind`;
      return fetch(apiUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data.results && data.results.distances && data.results.durations) {
            const distance = data.results.distances[0][1] / 1000;
            const duration = data.results.durations[0][1] / 60;
            return { mode, distance, duration };
          } else {
            throw new Error('Distance or duration not found in the response.');
          }
        })
        .catch(error => {
          console.error(`Error fetching data for ${mode}:`, error);
          return { mode, distance: null, duration: null, error: `Error fetching distance for ${mode}. ${error.message}` };
        });
    });

    // Execute all promises simultaneously using Promise.all
    Promise.all(promises)
      .then(results => {
        setResults(results);
        setError(null);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(`Error fetching distance. ${error.message}`);
      });
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Pincode-to-Pincode Distance Calculator</h2>
      <div className="mb-3">
        <label htmlFor="fromPincode" className="form-label">From Pincode:</label>
        <input type="text" style={{ maxWidth: '20%' }} className="form-control" id="fromPincode" value={fromPincode}    onInput={(e) => {
        // Allow only digits
        e.target.value = e.target.value.replace(/\D/g, ''); 
        
        // Ensure maximum length of 6 digits
        if (e.target.value.length > 6) {
          e.target.value = e.target.value.slice(0, 6);
        }

        setFromPincode(e.target.value);
      }}
      error={error && error.length > 0}
      helperText={error} />
      </div>
      <div className="mb-3">
        <label htmlFor="toPincode" className="form-label">To Pincode:</label>
        <input type="text" style={{ maxWidth: '20%' }} className="form-control" id="toPincode" value={toPincode}   onInput={(e) => {
        // Allow only digits
        e.target.value = e.target.value.replace(/\D/g, ''); 
        
        // Ensure maximum length of 6 digits
        if (e.target.value.length > 6) {
          e.target.value = e.target.value.slice(0, 6);
        }

        setToPincode(e.target.value);
      }}
      error={error && error.length > 0}
      helperText={error}/>
      </div>
      <button type="button" className="btn btn-primary" onClick={calculateDistance}>Calculate Distance</button>
      {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
      {results.length > 0 && (
        <div className="table-responsive mt-3">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Mode</th>
                <th>Distance (km)</th>
                <th>Duration (minutes)</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={result.mode}>
                  <td style={{ fontWeight: 'bold', backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>{result.mode}</td>
                  <td style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>{result.distance !== null ? result.distance.toFixed(2) : 'N/A'}</td>
                  <td style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>{result.duration !== null ? result.duration.toFixed(2) : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}    <Footer/>

    </div>
  );
};

export default DistanceCalculator;
