import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import OwnerInterface from './OwnerInterface';
import OwnerBooking from './OwnerBooking';
import AgentRegistration from './AgentRegistration';
import AgentInfo from './AgentInfo';
import NewTruck from './NewTruck';
import TruckPosting from './TruckPosting';
import AddSub from './AddSub';
import AgentUpdate from './AgentUpdate';
import NewDriver from './NewDriver';
import DriverInfo from './DriverInfo';
import Cal from './Cal';
import Footer from './Footer';
import MyProfile from './MyProfile';
import FAQ from './FAQ';
import MyForm from './MyForm';
import ForgotPasswordOwner from './ForgotPasswordOwner';
import DistanceCalculator from './DistanceCalculator';
import Location from './Location';
import Login from './Login';
export default function App() {


  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<OwnerInterface />} />
          <Route path='OwnerBooking' element={<OwnerBooking />} />
          <Route path='AgentRegistration' element={<AgentRegistration />} />
          <Route path='AgentInfo' element={<AgentInfo />} />
          <Route path='NewTruck' element={<NewTruck />} />
          <Route path='TruckPosting' element={<TruckPosting />} />
          <Route path='AddSub' element={<AddSub />} />
          <Route path='AgentUpdate' element={<AgentUpdate />} />
          <Route path='NewDriver' element={<NewDriver />} />
          <Route path='DriverInfo' element={<DriverInfo />} />
          <Route path='Cal' element={<Cal />} />
          <Route path='Footer' element={<Footer />} />
          <Route path='MyProfile' element={<MyProfile />} />
          <Route path='FAQ' element={<FAQ />} />
          <Route path='MyForm' element={<MyForm />} />
          <Route path='ForgotPasswordOwner' element={<ForgotPasswordOwner />} />
          <Route path='DistanceCalculator' element={<DistanceCalculator />} />
          <Route path='Location' element={<Location />} />
          <Route path='Login' element={<Login />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}
